// Components
import Hero from '../components/Hero';
import CardList from '../components/CardList';
import data from '../data/Data';
//test

const Home = () => {
    return ( 
        <div className="home">
            <Hero 
                title={data.hero.title} 
                subtitle={data.hero.subtitle} 
                image={data.hero.image} 
            />
            <div className="locations-container">
                <h2>Places</h2>
                <CardList cards={data.cards} />
            </div>
            <div className="experience-container">
                <h2>Professional Experience</h2>
                <CardList cards={data.experience} className="experience-cards" />
            </div>
        </div>
    );
}

export default Home;
