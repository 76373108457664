const Hero = ({ title, subtitle, image }) => {
    return (
        <div className="hero" style={{ backgroundImage: `url(${image})` }}>
            <div className="hero-content">
                <h1>{title}</h1>
                <h4>{subtitle}</h4>
            </div>
        </div>
    );
}

export default Hero;