const data = {
    cards: [
        { 
        id: 1,
        image: '/images/MA.jpg', 
        alt: 'Carrying backpack on sand path', 
        title: 'Boston, MA',
        tag: '18 years',
        color: '#008000' // Green
    },
    { 
        id: 2,
        image: '/images/IN.jpg', 
        alt: 'Sample Gates Indiana University', 
        title: 'Bloomington, IN',
        tag: '4 years',
        color: '#990000' // Crimson (IU colors)
    },
    { 
        id: 3,
        image: '/images/SF.jpg',     
        alt: 'Golden Gate Bridge', 
        title: 'San Francisco, CA',
        tag: '3 months',
        color: '#FF7F50' // Coral (similar to Golden Gate)
    },
    { 
        id: 4,
        image: '/images/CHI.jpg', 
        alt: 'Chicago River and City', 
        title: 'Chicago, IL',
        tag: '3.5 years',
        color: '#00B2E3' // Chicago blue
    },
    { 
        id: 5,
        image: '/images/CO.jpg', 
        alt: 'Lake and Mountain Range', 
        title: 'Denver, CO',
        tag: 'current',
        color: '#2E8B57' // Mountain green
        }
    ],
    hero: {
        title: 'Building Things That Catch My Interest',
        subtitle: 'Discover Brett’s Passions, Projects, and Life Adventures',
        image: '/images/Frisco.jpg'
    },
    sportsTeams: [
        {
            id: 1,
            image: '/images/New_England_Patriots_logo.png',
            alt: 'New England Patriots Logo',
            title: 'New England Patriots',
            tag: '6 Championships',
            color: '#002244', // Patriots Navy Blue
            championships: [
                "2002",
                "2004",
                "2005",
                "2015",
                "2017",
                "2019"
            ]
        },
        {
            id: 2,
            image: '/images/Boston_Celtics.svg.webp',
            alt: 'Boston Celtics Logo',
            title: 'Boston Celtics',
            tag: '2 Championship',
            color: '#007A33', // Celtics Green
            championships: [
                "2008",
                "2024"
            ]
        },
        {
            id: 3,
            image: '/images/boston-red-sox-logo-transparent.png',
            alt: 'Boston Red Sox Logo',
            title: 'Boston Red Sox',
            tag: '4 Championships',
            color: '#BD3039', // Red Sox Red
            championships: [
                "2004",
                "2007",
                "2013",
                "2018"
            ]
        },
        {
            id: 4,
            image: '/images/Boston_Bruins.svg',
            alt: 'Boston Bruins Logo',
            title: 'Boston Bruins',
            tag: '1 Championship',
            color: '#FFB81C', // Bruins Gold
            championships: [
                "2011"
            ]
        },
        {
            id: 5,
            image: '/images/Indiana_Hoosiers_logo.svg',
            alt: 'Indiana Hoosiers Basketball Logo',
            title: 'Indiana Basketball',
            tag: '0 Championships',
            color: '#990000', // IU Crimson
            championships: []
        },
        {
            id: 6,
            image: '/images/Indiana_Hoosiers_logo.svg',
            alt: 'Indiana Hoosiers Football Logo',
            title: 'Indiana Football',
            tag: '0 Championships',
            color: '#990000', // IU Crimson
            championships: []
        }
    ],
    experience: [
        {
            id: 1,
            image: '/images/contentfulLogo.png',
            alt: 'Contentful Logo',
            company: 'Contentful',
            tag: 'CMS & DXP',
            color: '#4046CA' // Contentful brand blue
        },
        {
            id: 2,
            image: '/images/crunchbaseLogo.png',
            alt: 'Crunchbase Logo',
            company: 'Crunchbase',
            tag: 'Data & Market Insights',
            color: '#0288D1' // Crunchbase brand blue
        },
        {
            id: 3,
            image: '/images/salesforceLogo.png',
            alt: 'Salesforce Logo',
            company: 'Salesforce',
            tag: 'CRM, CX, and Analytics',
            color: '#00A1E0' // Salesforce brand blue
        }
    ]
}

export default data;