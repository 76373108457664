import CardList from '../components/CardList';
import data from '../data/Data';

const Sports = ({ sportsTeams }) => {
    const championshipsWitnessed = data.sportsTeams.reduce((total, team) => 
        total + team.championships.length, 0);
    const totalChampionshipsWitnessed = championshipsWitnessed;
    return (
        <div className="sports-container">
            <div className="championships-witnessed-card">
                <div className="championships-witnessed">
                    <h2>{totalChampionshipsWitnessed}</h2>
                    <p>Total Championships Witnessed</p>
                </div>
            </div>
            <div className="sports-teams">
                <CardList cards={data.sportsTeams} />
            </div>
        </div>
    );
}

export default Sports;