import NavBar from './components/NavBar';
import Home from './pages/home';
import Sports from './pages/Sports';
import Movies from './pages/Movies';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NotFound from './pages/NotFound';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sports" element={<Sports />} />
            <Route path="/movies" element={<Movies />} />
            <Route path="*" element={<NotFound />} />
          </Routes> 
        </div>
      </div>
    </Router>
  );
}

export default App;
